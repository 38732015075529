<template>
  <div class="home">
    <img src="logo.svg" width="300px" alt=""> <br>
    <hr>
    <HelloWorld msg="Generador de Firmas Automatico"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
<style>
.home{
  background-color: #fff;
  padding: 10%;
}
</style>
